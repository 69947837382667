<template>
	<div
		class="full-height"
	>
		<h6>
			{{ program.name }}
			<span>
				<button
					v-for="(item, index) in codes.monthly_service"
					:key="'monthly_service_' + index"
					class="pa-10-20 mr-10 "
					:class="search.service == item.code ? 'bg-identify' : 'bg-identify-outline'"
					@click="search.service = item.code; getSearch(1)"
				>{{ item.name }}</button>
			</span>
		</h6>

		<Search
			class="mt-10 box-ddd"
			:search="search"
			:option="search_option"

			@click="getSearch(1)"
			@reset="reset"
			@setSearchDateType="setSearchDateType"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.type"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_type"
					:key="'type_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.paymentType"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_payment"
					:key="'status_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.state"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_state"
					:key="'status_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 box-ddd"
		>
			<div
				class="pa-10 bg-identify"
			>
				{{ search.sDate | transDate }} ~ {{ search.eDate | transDate }}
			</div>
			<div
				v-if="summary.length > 0"
				class=" bg-white justify-space-between"
			>
				<div class="pa-20 flex-1 bg-icon-round-dot border-right">
					<div class="color-identify">{{  summary[0].name }}</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary[0].count | makeComma}}건</div>
				</div>

				<div class="pa-20 flex-1 bg-icon-round-cancel border-right">
					<div class="color-identify">{{  summary[1].name }}</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary[1].count | makeComma}}건</div>
				</div>

				<div class="pa-20 flex-1 bg-icon-round-check">
					<div class="color-identify">{{  summary[2].name }}</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary[2].count | makeComma}}건</div>
				</div>
			</div>
		</div>

		<div class="mt-10 pa-10 box-ddd bg-white full-height  ">
			<div class="justify-space-between">

				<div>
					<button
						class="bg-identify pa-4-10 mr-10 vertical-middle"
						@click="isReview"
					>선택 심사 완료</button>
				</div>

				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>
			<table
				v-if="items.length > 0"
				class="mt-10 table table-even"
			>
				<colgroup>

					<col width="40px" />
					<col width="40px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>
						<input
							v-model="is_all"
							type="checkbox"
						>
					</th>
					<th>NO</th>
					<th>신청일시</th>
					<th>신청자</th>
					<th>구분</th>
					<th>결제 예정 금액</th>
					<th>예정 수수료</th>
					<th>심사 경과 기간</th>

					<th>심사상태</th>
					<th>결제상태</th>
					<th>결제일</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>
						<input
							v-if="item.state != 1"
							v-model="item.is_select"
							type="checkbox"
						>
					</td>
					<td>{{ item.idx }}</td>
					<td>{{ item.registerDate}}</td>
					<td>{{ item.user }}</td>
					<td>{{ item.type }}</td>

					<td class="text-right">{{ item.amount }}</td>
					<td class="text-right">{{ item.feeAmount }}</td>
          <td>D+{{ item.auditPlusDay }}</td>
					<td :class="{ 'color-green' : item.auditState === '심사완료','color-red': item.auditState === '반려','color-identify': item.auditState === '심사대기' }">{{ item.auditState }}</td>
					<td :class="{ 'color-green' : item.paymentState === '결제 완료' }">{{ item.paymentState }}</td>
					<td>
            <span v-if="item.paymentDate">{{ item.paymentDate }}</span>
            <span v-else>-</span>
          </td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"

				@click="getSearch"
			></Pagination>

		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"
		></Excel>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:bottom="true"
			:top="true"
			:slot_bottom="true"

			title="월세 심사 상세"

			@close="close"
		>
			<div
				slot="modal-content"
				class=" bg-white full-width"
			>
				<MonthlyReviewDetail
					v-if="item.idx"
					:idx="item.idx"
					:service="item.service"

					@setNotify="setNotify"
					@doUpdate="doUpdate"
					@close="close"
				></MonthlyReviewDetail>
			</div>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"
			:top="true"
			:bottom="true"

			title="선택 심사 완료"

			content="선택하신 항목들을 심사완료 처리 하시겠습니까?"
			content_class="ptb-30"
			width="380px"

			@close="close"
			@click="doConfirm"
			@cancel="close"
		></Modal>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import MonthlyReviewDetail from "@/view/Monthly/MonthlyReviewDetail";

export default {
	name: 'QuickSettlementList'
	,
	components: {MonthlyReviewDetail, Modal, Excel, Search, Pagination},
	props: ['user', 'codes', 'Axios', 'date', 'query']
	,data: function(){
		return {
			program: {
				name: '월세 심사'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				route: this.$route.name
				,page: this.query.page ? this.query.page : 1
				,size: this.query.size ? this.query.size : 10
				,search_type: this.query.search_type ? this.query.search_type : ''
				,keyword: this.query.keyword ? this.query.keyword : ''
				,startDate: this.query.startDate ? this.query.startDate : this.date.getSearchBaseDate('-')
				,endDate: this.query.endDate ? this.query.endDate : this.date.getToday('-')
				,sDate: this.query.sDate ? this.query.sDate : ''
				,eDate: this.query.eDate ? this.query.eDate : ''
				,search_date_type: this.query.search_date_type ? this.query.search_date_type : 'weekly'
				,service: this.query.service ? this.query.service : 0
				,type: this.query.type ? this.query.type : ''
				,paymentType: this.query.paymentType ? this.query.paymentType : ''
				,state: this.query.state ? this.query.state : ''
				,total_count: 0
			}
			,search_option: {
				sDate: true
				,eDate: true
				,search_type: [
					{ name: '이름', column: 'name'}
					,{ name: '아이디', column: 'id'}
				]
				,is_search_date_type: true
			}
			,is_excel: false
			,excel_data: {
				name: '심사 내역'
				,header: [
					{ key: 0, name: 'NO', column: 'idx'}
					,{ key: 0, name: '신청일시', column: 'registerDate'}
					,{ key: 0, name: '신청자', column: 'userName'}
					,{ key: 0, name: '서비스 구분', column: 'service_name'}
					,{ key: 0, name: '구분', column: 'type_name'}
					,{ key: 0, name: '납입타입', column: 'payment_name'}
					,{ key: 0, name: '1회 결제금액', column: 'amount'}
					,{ key: 0, name: '총 결제금액', column: 'totalAmount'}
					,{ key: 0, name: '1회 수수료', column: 'fee'}
					,{ key: 0, name: '총 수수료', column: 'totalFee'}
					,{ key: 0, name: '할부', column: 'installment'}
					,{ key: 0, name: '심사기간', column: 'remain'}
					,{ key: 0, name: '심사여부', column: 'state_name'}
				]
				,content: null
			}
			,items: []
			,item: {

			}

			,summary: []
			,is_modal: false
			,modal_option: {
			}
			,is_modal2: false
			,modal_option2: {}
			,is_all: false
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.monthly_service.length; i++){
					if(item.service == self.codes.monthly_service[i].code){
						item.service_name = self.codes.monthly_service[i].name
						item.service_color = self.codes.monthly_service[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_type.length; i++){
					if(item.type === self.codes.monthly_type[i].code){
						item.type_name = self.codes.monthly_type[i].name
						item.service_color = self.codes.monthly_type[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_payment.length; i++){

					if (item.paymentType == self.codes.monthly_payment[i].code) {
						item.payment_name = self.codes.monthly_payment[i].name
						item.payment_color = self.codes.monthly_payment[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_state.length; i++){
					if(item.state == self.codes.monthly_state[i].code){
						item.state_name = self.codes.monthly_state[i].name
						item.state_color = self.codes.monthly_state[i].color
						break
					}
				}
				if(item.state == '0') {
					if(item.remainAuditDay > 0){
						item.remain = (item.remainAuditDay) + '일 초과'
						item.remain_color = 'red'
					}else{
						item.remain = (item.remainAuditDay * -1) + '일 남음'
					}
				}else{
					item.remain = '-'
				}
        console.log(item, 'item')
				return item

			})
		}

		,select_items: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.idx)
				}
			})

			return items
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$set(this.search, 'startDate', this.search.sDate)
				this.$set(this.search, 'endDate', this.search.eDate)

				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'monthly/audit'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.search.total_count = result.data.tableList.totalCount
					this.summary = result.data.statistics
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
			}finally {
				this.$emit('offLoading')
			}
		}
		,toDetail: function(item){
			this.item = item
			this.is_modal = true
		}
		,reset: function(){
			this.search = {
				route: this.$route.name
				,page:1
				,size:10
				,search_type:''
				,keyword:''
				,sDate: this.date.getLastDate(this.date.getToday('-'), 7, '-')
				,eDate: this.date.getToday('-')
				,search_date_type: 'weekly'
				,service: 0
				,type: ''
				,paymentType: ''
				,state: ''
				,total_count: 0
			}

			this.getData()
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			//this.$emit('push', { name: this.$route.name, params: this.$route.params, query: this.search })

			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,close: function(){
			this.is_modal = false
			this.is_modal2 = false
		}
		,cancel: async function(){

		}
		,isReview: function(){
			if(this.select_items.length > 0){
				this.is_modal2 = true
			}else{
				this.$emit('setNotify', { type: 'error', message: this.$language.common.not_select})
			}
		}

		,doAction: function(){
			if(this.modal_option.type == 'review'){
				this.doReview()
			}
		}
		,doReview: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: ''
					,data: {
						items: this.select_items
					}
				})

				if(result.success){
					this.getSearch()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					console.log(result)
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				alert(11)
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$emit('offLoading')
			}
		}
		,doSettlement: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: ''
					,data: {
						items: this.select_items
					}
				})

				if(result.success){
					this.getSearch()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				alert(11)
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$emit('offLoading')
			}
		}
		,doConfirm: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'monthly/audit/confirm'
					,data: {
						monthlyIdxList: this.select_items
						,reason: this.item.reason
						,service: this.search.service
						,state: 6
					}
				})

				if(result.success){
					this.close()
					this.getSearch()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$emit('offLoading')
			}
		}
		,doUpdate: async function(item, state){
			try{
				this.$emit('onLoading')
				if(!item.agree){
					this.$emit('setNotify', { type: 'error', message: '주의사항을 확인해주세요'})
					return
				}
				const result = await this.Axios({
					method: 'put'
					,url: 'monthly/audit/confirm/' + item.idx
					,data: {
						reason: item.reason
						,service: item.service
						,state: state
					}
				})

				if(result.success){
					this.close()
					this.getSearch()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		, setNotify: function( {type, message}){
			this.$emit('setNotify', { type: type, message: message })
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
		,'search.search_date_type': {
			immediate: true
			, handler: function(call){
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
.bg-icon-round-dot { background: url('../../assets/images/icon/icon-round-dot.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-round-cancel { background: url('../../assets/images/icon/icon-round-cancel.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-round-check { background: url('../../assets/images/icon/icon-round-check.svg') no-repeat 10px center; padding-left: 70px;}
</style>